import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "reviewing-rules"
    }}>{`Reviewing rules`}</h1>
    <p>{`The rules need to change as your teenager gets older. Adjust rules for your teenager’s behaviour over time to support and encourage their developing sense of independence and responsibility.`}</p>
    <p>{`For example, let's look at a comparison between rules for a 12 year old and a 17 year old:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rule`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`12 year old`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`17 year old`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Curfew`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7pm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10pm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bedtime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9pm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10:30pm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consequences`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No TV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Extra household chores for 2 days`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chores`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Keep own room tidy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Walk the dog or wash the dishes. Do own laundry`}</td>
        </tr>
      </tbody>
    </table>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m3/14.svg" alt="Discussion with kids" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      